<template>
    <div class="base-quac">
        <banner-principal />
        <what-is-quac />
        <how-found />
        <BenefitQuac />
        <BreakeNav />
        <AliadosQuac />
        <!-- <DownloadApp /> -->
    </div>
</template>
<script>
import BannerPrincipal from '@/components/templates/BannerPrincipal.vue'
import WhatIsQuac from '@/components/templates/WhatIsQuac.vue'
import HowFound from '@/components/templates/HowFound.vue'
import BenefitQuac from '@/components/templates/BenefitQuac.vue'
import BreakeNav from '@/components/templates/BreakeNav.vue'
import AliadosQuac from '@/components/templates/AliadosQuac.vue'
// import DownloadApp from '@/components/templates/DownloadApp.vue'
export default {
    name: 'NewHome',
    components: {
        BannerPrincipal,
        WhatIsQuac,
        HowFound,
        BenefitQuac,
        BreakeNav,
        AliadosQuac,
        // DownloadApp,
    }
}
</script>
<style scoped>
    .img-breake-nav {
        max-width: 1920px;
        width: 100%;
        margin-bottom: 2rem;
    }
</style>